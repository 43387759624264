<template>
  <div>
    <h5 class="p-t-20 bold p-b-10 auth-title">
      {{ $t("auth.forgot password page") }}
    </h5>
    <br/><br/><br/>
    <validation-observer
      ref="form_forgot_password"
      v-slot="{ passes, valid, validated }"
    >
      <form
        novalidate
        autocomplete="off"
        @submit.prevent="passes(forgotPassword)"
      >
        <app-header-notice ref="notice"></app-header-notice>
        <label class="m-b-5 input-label control-label">
          {{ $t("common.mail address") }}
          <span class="app-require">＊</span>
        </label>
        <app-input
          input-style="normal"
          name="email"
          type="email"
          :rules="VALIDATE.V_EMAIL_REQUIRED"
          v-model="email"
        ></app-input>

        <label class="notifi-resetpass m-t-20">{{ $t("auth.forgot password note") }}</label>
<!--        <p>{{ $t("auth.forgot password note") }}</p>-->
        <br/><br/>
        <div class="row">
<!--          <div class="col-md-6">-->
<!--            <button-->
<!--              @click="__redirect(loginRoute)"-->
<!--              class="app-btn app-btn-orange"-->
<!--              style="width: 100%"-->
<!--              type="button"-->
<!--            >-->
<!--              {{ $t("common.cancel") }}-->
<!--            </button>-->
<!--          </div>-->
          <div class="col-md-2" style="margin: auto">
            <button
              class="app-btn app-btn-blue m-l-10"
              style="width: 100%"
              type="submit"
            >
              {{ $t("common.send") }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { AUTH } from "@constants";

export default {
  name: "ForgotPasswordPage",

  data() {
    let loginRoute = AUTH[this.$store.state.auth.currentAuth.role].routes.login;
    return {
      email: null,
      loginRoute: loginRoute,
    };
  },

  methods: {
    async forgotPassword() {
      let role = AUTH[this.$store.state.auth.currentAuth.role].role_id;
      const res = await this.$request.post(this.ENDPOINT.AUTH_FORGOT_PASSWORD, {
        email: this.email,
        role: role,
      });
      if (!res.hasErrors()) {
        this.$refs.notice.setSuccess(res.data.msg);
      } else {
        this.$refs.notice.setError(res.data.msg);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  @import "@assets/pages/login.scss";
</style>
